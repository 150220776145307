<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav> -->
    <!-- <div class="news">
      <Tag></Tag>
    </div> -->
    <div class="main">
      <img class="introduceImg" :src="introduceImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
      <img class="developmentImg" :src="developmentImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
      <img class="royalImg" :src="royalImg" alt="" ondragstart="return false;" oncontextmenu="return false;">
      <img class="professor1" :src="professor1" alt="" ondragstart="return false;" oncontextmenu="return false;">
      <img class="professor2" :src="professor2" alt="" ondragstart="return false;" oncontextmenu="return false;">
      <img class="professor3" :src="professor3" alt="" ondragstart="return false;" oncontextmenu="return false;">
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Tag from "./modules/Tag.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Tag,
  },
  data() {
    return {
      introduceImg:require('@/assets/1new/关于我们/2.jpg'),
      developmentImg:require('@/assets/1new/关于我们/3.jpg'),
      royalImg:require('@/assets/1new/关于我们/4.jpg'),
      professor1:require('@/assets/1new/关于我们/5.jpg'),
      professor2:require('@/assets/1new/关于我们/6.jpg'),
      professor3:require('@/assets/1new/关于我们/7.jpg'),
    };
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   .right {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }
.introduceImg{
  width: 1200px;
  height: 511px;
}
.developmentImg{
  width: 1200px;
  height: 1325px;
}
.royalImg{
  width: 1200px;
  height: 1223px;
}
.royalImg{
  width: 1200px;
  height: 1223px;
}
.professor1{
  width: 1200px;
  height: 582px;
}
.professor2{
  width: 1200px;
  height:645px;
}
.professor3{
  width: 1200px;
  height: 842px;
}
.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
