<template>
  <div class="index">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="公司简介" name="first">
        <div class="box" v-for="item in list" :key="item">
          <p class="name">{{ item.name }}</p>
          <p class="introduce">{{ item.introduce }}</p>
          <video
            class="video"
            :src="item.video"
            poster="item.img"
            autoplay
            controls
            muted
          ></video>
        </div>
      </el-tab-pane>
      <el-tab-pane label="发展历程" name="second">
        <div class="wrap">
          <img class="developmentImg" :src="developmentImg" alt="" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="关于我们" name="third">
        <div class="boxB" v-for="item in list2" :key="item">
          <p class="name">{{ item.name }}</p>
          <div class="main">
            <div class="left">
              <div class="item"  v-for="item in area" :key="item">
                <p class="p1 p">{{item.name}}</p>
                <p class="p2 p">{{item.tel}}</p>
                <p class="p3 p">{{item.location}}</p>
              </div>
            </div>
            <div class="right">
              <img class="map" :src="mapImg" alt="" />
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <!-- -->
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      list: [
        {
          video: require("@/assets/关于我们/djx.mp4"),
          name: "公司简介",
          introduce:
            "新生涯--立思辰集团旗下品牌，是国内首家中学生涯规划教育一体化解决方案提供者，多年来致力于生涯规划领域探究，竭力打造成为中国中学生涯规划第一品牌。 新生涯率先搭建中学生涯规划实践基地，已与包括衡水中学、英德市教育局等在内的近百家重点中学及教育局建立了深度合作，培训师资遍布北京、天津、河北、山东、浙江、广东等二十几个省市，人数超过2万人；自主研发一整套课程配套中学生涯课程教材及教具；推出200节在线智慧成长课程；着力从学校、教师、学生和家长四个层面全方位推行生涯教育，打造立体式一体化生涯教育解决方案。将生涯规划与学业规划融合应用，实现专业+院校的双匹配，获得众多中学和师生的一致认可，在业界有着良好的口碑和声誉。",
          img: require("@/assets/peiXunNeiRong.png"),
          
        },
      ],
      developmentImg: require("@/assets/关于我们/fazhan.jpg"),
          mapImg: require("@/assets/关于我们/地图.png"),
      list2: [
        {
          video: require("@/assets/关于我们/djx.mp4"),
          name: "联系我们",
          introduce: "新生涯",
          img: require("@/assets/关于我们/地图.png"),
        },
      ],
      area:[
        {
          name:"北京总校",
          tel:"电话:010-83058502",
          location:"地址：中关村软件园立思辰大厦",
        },
        {
          name:"山西分校",
          tel:"0351-5228899",
          location:"地址:太原小店区学府街坞城路口学府一号商务公寓1714",
        },
        {
          name:"威海分校",
          tel:"0631-5678490",
          location:"环翠区文化西路69-10号",
        },
      ]
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  .box {
    margin-top: 20px;
    .name {
      font-size: 30px;
      color: #333;
      text-align: center;
      margin-bottom: 20px;
    }
    .introduce {
      font-size: 18px;
      line-height: 32px;
      //
    }
    .video {
      width: 1200px;
      height: 680px;
    }
  }
  .developmentImg {
    width: 1080px;
    height: 1673px;
  }
  .boxB {
    margin-top: 20px;
    .name {
      font-size: 30px;
      color: #333;
      text-align: center;
      margin-bottom: 20px;
    }
    .main {
      width: 1200px;
      height: 480px;
      margin-top: 20px;
      // background-color: pink;
      .left {
        // background-color: skyblue;
        width: 440px;
        height: 480px;
        float: left;
        .item{
          width: 440px;
          height: 110px;
          border-bottom: 1px solid #f5f5f5;
          .p{
            margin-top: 20px;
          }
          .p1{
            font-size: 18px;
            color: #111;
            font-weight: bold;
          }
           .p2{
            font-weight: bold;
            font-size: 14px;
            color: red;
          }
           .p3{
            font-size: 12px;
            color: #444;
          }
        }
      }
      .right {
        width: 730px;
        height: 480px;
        float: left;
        overflow: hidden;
        .map{
           width: 730px;
        height: 480px;
        }
      }
    }
  }
}
</style>